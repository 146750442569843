.row{
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
}
.row-center{
  @extend .row;
  justify-content: center;
}
.row-start{
  @extend .row;
  justify-content: flex-start;
}
.row-end{
  @extend .row;
  justify-content: flex-end;
}
.row-between{
  @extend .row;
  justify-content: space-between;
}
.form{
  display: flex;
  flex-basis: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: column;
  padding: 44px;
  box-sizing: border-box;
}

@for $i from -10 to 32 {
  $num : $i * 2;
  .mt#{$num}{
    margin-top: $num+px;
  }
  .m#{$num}{
    margin: $num+px;
  }
}

.mt220{
  margin-top: 220px;
}
.mt214{
  margin-top: 214px;
}
