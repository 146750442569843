.cell{
  position: absolute;
}
.cell.static{
  float:left; display: none; position: relative !important;
}
.cell.free{
  display: none;
}
.masonry{
  &:after{
    display: block; content: " "; float: none; clear: both; overflow: hidden;
  }
}
@for $i from 0 to 4{
  .static.cell-0, .static.cell-1 {
    display: block !important;
    float: left;
    position: relative;
  }
  .static.cell-0 {
    margin-right: 26px;
  }
  .static.cell-1{

  }
  .static.cell{
    display: none;
  }
}
