.icon{
  width: 64px;
  height: 64px;
  &.dzred{

  }
  &.gray{
    background: #767676;
  }
  /********************** icon图片 *************************/
  &.icon_arrow_left{
    mask: url("../assets/images/icon_arrow_left.svg") center/24px 36px no-repeat;
  }
  &.icon_arrow_right{
    background: url("../assets/images/icon_arrow-right.svg") center no-repeat;
  }
  &.icon-loading{
    mask: url("../assets/images/icon_loading.svg");
  }
  &.icon-board-list-xiezuo{
    mask: url("../assets/images/icon_xiezuo.svg") center/36px 36px no-repeat;
  }
  &.icon-board-list-private{
    mask: url("../assets/images/icon_board_private.svg")  center/36px 36px no-repeat;
  }
}
