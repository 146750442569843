$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
.sticky{
  transform: translate3d( 0, 0, 2PX );
  transition: all 0.3s ease-in-out;
  z-index: 100;
  &.top{ top:0; }
  &.bottom{ bottom:0; }
  &.relative{
    position: relative; opacity: 1;
  }
  &.relativeHidden{
    position: relative; opacity: 0;
  }
  &.fixed{
    position: fixed; opacity: 1; left:0; right:0;
  }
  &.fixedHidden{
    position: fixed; opacity: 0; left:0; right:0; transform: translate3d( 0, -100%, 2PX );pointer-events: none;
  }
}
