@charset "UTF-8";
.cell {
  position: absolute; }

.cell.static {
  float: left;
  display: none;
  position: relative !important; }

.cell.free {
  display: none; }

.masonry:after {
  display: block;
  content: " ";
  float: none;
  clear: both;
  overflow: hidden; }

.static.cell-0, .static.cell-1 {
  display: block !important;
  float: left;
  position: relative; }

.static.cell-0 {
  margin-right: 26px; }

.static.cell {
  display: none; }

.static.cell-0, .static.cell-1 {
  display: block !important;
  float: left;
  position: relative; }

.static.cell-0 {
  margin-right: 26px; }

.static.cell {
  display: none; }

.static.cell-0, .static.cell-1 {
  display: block !important;
  float: left;
  position: relative; }

.static.cell-0 {
  margin-right: 26px; }

.static.cell {
  display: none; }

.static.cell-0, .static.cell-1 {
  display: block !important;
  float: left;
  position: relative; }

.static.cell-0 {
  margin-right: 26px; }

.static.cell {
  display: none; }

.cl:after {
  content: "";
  display: block;
  clear: both; }

.ani {
  transition: all 0.5s ease; }

.font {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,"PingFangSC-Regular", "SourceHanSansSC-Regular", "Microsoft Yahei",SourceHanSansCN-Medium,SourceHanSansCN,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important; }

.oneline {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis; }

.svgGray {
  fill: #767676; }

.svgRed {
  fill: #e60023; }

.font-regular, .h3 {
  font-weight: normal;
  font-style: normal; }

.font-medium, .qrcodeBlock .qrcodeBlock-desc {
  font-weight: normal;
  font-style: normal; }

.font-bold, .h2 {
  font-weight: bold;
  font-style: normal; }

.row, .row-center, .row-start, .row-end, .row-between {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0; }

.row-center {
  justify-content: center; }

.row-start {
  justify-content: flex-start; }

.row-end {
  justify-content: flex-end; }

.row-between {
  justify-content: space-between; }

.form {
  display: flex;
  flex-basis: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: column;
  padding: 44px;
  box-sizing: border-box; }

.mt-20 {
  margin-top: -20px; }

.m-20 {
  margin: -20px; }

.mt-18 {
  margin-top: -18px; }

.m-18 {
  margin: -18px; }

.mt-16 {
  margin-top: -16px; }

.m-16 {
  margin: -16px; }

.mt-14 {
  margin-top: -14px; }

.m-14 {
  margin: -14px; }

.mt-12 {
  margin-top: -12px; }

.m-12 {
  margin: -12px; }

.mt-10 {
  margin-top: -10px; }

.m-10 {
  margin: -10px; }

.mt-8 {
  margin-top: -8px; }

.m-8 {
  margin: -8px; }

.mt-6 {
  margin-top: -6px; }

.m-6 {
  margin: -6px; }

.mt-4 {
  margin-top: -4px; }

.m-4 {
  margin: -4px; }

.mt-2 {
  margin-top: -2px; }

.m-2 {
  margin: -2px; }

.mt0 {
  margin-top: 0px; }

.m0 {
  margin: 0px; }

.mt2 {
  margin-top: 2px; }

.m2 {
  margin: 2px; }

.mt4 {
  margin-top: 4px; }

.m4 {
  margin: 4px; }

.mt6 {
  margin-top: 6px; }

.m6 {
  margin: 6px; }

.mt8 {
  margin-top: 8px; }

.m8 {
  margin: 8px; }

.mt10 {
  margin-top: 10px; }

.m10 {
  margin: 10px; }

.mt12 {
  margin-top: 12px; }

.m12 {
  margin: 12px; }

.mt14 {
  margin-top: 14px; }

.m14 {
  margin: 14px; }

.mt16 {
  margin-top: 16px; }

.m16 {
  margin: 16px; }

.mt18 {
  margin-top: 18px; }

.m18 {
  margin: 18px; }

.mt20 {
  margin-top: 20px; }

.m20 {
  margin: 20px; }

.mt22 {
  margin-top: 22px; }

.m22 {
  margin: 22px; }

.mt24 {
  margin-top: 24px; }

.m24 {
  margin: 24px; }

.mt26 {
  margin-top: 26px; }

.m26 {
  margin: 26px; }

.mt28 {
  margin-top: 28px; }

.m28 {
  margin: 28px; }

.mt30 {
  margin-top: 30px; }

.m30 {
  margin: 30px; }

.mt32 {
  margin-top: 32px; }

.m32 {
  margin: 32px; }

.mt34 {
  margin-top: 34px; }

.m34 {
  margin: 34px; }

.mt36 {
  margin-top: 36px; }

.m36 {
  margin: 36px; }

.mt38 {
  margin-top: 38px; }

.m38 {
  margin: 38px; }

.mt40 {
  margin-top: 40px; }

.m40 {
  margin: 40px; }

.mt42 {
  margin-top: 42px; }

.m42 {
  margin: 42px; }

.mt44 {
  margin-top: 44px; }

.m44 {
  margin: 44px; }

.mt46 {
  margin-top: 46px; }

.m46 {
  margin: 46px; }

.mt48 {
  margin-top: 48px; }

.m48 {
  margin: 48px; }

.mt50 {
  margin-top: 50px; }

.m50 {
  margin: 50px; }

.mt52 {
  margin-top: 52px; }

.m52 {
  margin: 52px; }

.mt54 {
  margin-top: 54px; }

.m54 {
  margin: 54px; }

.mt56 {
  margin-top: 56px; }

.m56 {
  margin: 56px; }

.mt58 {
  margin-top: 58px; }

.m58 {
  margin: 58px; }

.mt60 {
  margin-top: 60px; }

.m60 {
  margin: 60px; }

.mt62 {
  margin-top: 62px; }

.m62 {
  margin: 62px; }

.mt220 {
  margin-top: 220px; }

.mt214 {
  margin-top: 214px; }

html {
  height: 100%; }

body {
  font-size: 24px;
  -webkit-text-size-adjust: none;
  overflow: hidden;
  position: relative;
  touch-action: none; }

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex: 1;
  position: static; }

p {
  margin: 0 auto; }

input, textarea {
  border: 0 none;
  outline: 0 none;
  padding: 0;
  user-select: auto;
  border-radius: 0;
  -webkit-appearance: none;
  /*清除ios默认圆角*/ }
  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #AAAAAA; }

* {
  box-sizing: border-box;
  user-select: none; }

#app {
  width: 100%; }

iframe {
  visibility: hidden;
  width: 0;
  height: 0;
  border: 0 none; }

.page {
  display: flex;
  justify-content: center;
  min-height: 100%;
  background: #fff; }

.h2 {
  font-size: 40px;
  color: #303030;
  line-height: 48px;
  font-weight: 600;
  text-align: center; }

.h3 {
  font-size: 32px;
  font-weight: 400;
  color: #767676;
  line-height: 48px;
  text-align: center; }

.qrcodeBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .qrcodeBlock .qrcodeBlock-img {
    width: 240px;
    height: 240px; }
  .qrcodeBlock .qrcodeBlock-desc {
    font-size: 24px;
    font-weight: 500;
    color: #767676;
    line-height: 24px;
    margin-top: 24px; }

a {
  color: #767676; }
  a:active {
    color: #FF4D4D; }

img:focus, svg:focus {
  outline: 0 none; }

.cell {
  position: absolute; }

.view {
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; }
  .view > div:first-child {
    height: 100%; }

.scrollWrapper {
  height: 100%; }

.console {
  position: fixed;
  left: 30px;
  bottom: 100px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 30px;
  z-index: 100;
  pointer-events: none; }

.btn-weixin {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  height: 56px;
  border: 0 none;
  background: transparent; }
  .btn-weixin .btn-weixin-icon {
    width: 56px;
    height: 56px;
    background: #28AC0C;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 24px; }
    .btn-weixin .btn-weixin-icon svg, .btn-weixin .btn-weixin-icon img {
      width: 36px;
      height: 36px; }

.cell {
  display: flex; }

.van-action-sheet {
  border-radius: 22px 22px 0 0; }
  .van-action-sheet .van-action-sheet__item, .van-action-sheet .van-action-sheet__cancel {
    font-size: 30px;
    font-weight: 500;
    color: #333333;
    line-height: 42px;
    letter-spacing: 1px;
    padding: 30px;
    border-bottom: 1px solid #EDEDED;
    position: relative; }
  .van-action-sheet .van-action-sheet__gap {
    background-color: #EDEDED;
    height: 14px; }

.van-popup--bottom {
  border-radius: 0 !important; }
  .van-popup--bottom .van-picker__toolbar {
    height: 100px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .van-popup--bottom .van-picker__toolbar .van-picker__confirm {
      font-size: 32px;
      font-weight: 600;
      color: #FF352C; }
    .van-popup--bottom .van-picker__toolbar .van-picker__cancel {
      font-size: 32px;
      font-weight: 600;
      color: #333333;
      opacity: 0.6; }
  .van-popup--bottom .van-picker__columns {
    background: #F8F8F8; }
    .van-popup--bottom .van-picker__columns .van-picker__mask {
      background: transparent; }
    .van-popup--bottom .van-picker__columns .van-picker-column__item {
      font-size: 28px; }
    .van-popup--bottom .van-picker__columns .van-hairline-unset--top-bottom::after {
      border-width: 1px 0;
      border-color: #C7C7C7; }

.van-switch {
  background: #aaaaaa; }

.pinFavToast.van-toast {
  width: 706px;
  height: 136px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  font-size: 32px;
  font-weight: 400;
  color: #333333;
  line-height: 48px;
  letter-spacing: 1px;
  transform: none;
  left: 50%;
  margin-left: -345px;
  box-sizing: border-box;
  max-width: none; }

.blurPage {
  filter: blur(4px); }
