@charset "UTF-8";
.cl:after {
  content: "";
  display: block;
  clear: both; }

.ani {
  transition: all 0.5s ease; }

.font {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,"PingFangSC-Regular", "SourceHanSansSC-Regular", "Microsoft Yahei",SourceHanSansCN-Medium,SourceHanSansCN,ヒラギノ角ゴ Pro W3,Hiragino Kaku Gothic Pro,メイリオ,Meiryo,ＭＳ Ｐゴシック,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important; }

.oneline {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis; }

.svgGray {
  fill: #767676; }

.svgRed {
  fill: #e60023; }

.wrapper {
  position: fixed;
  width: 600px;
  height: 110px;
  z-index: 100;
  left: 50%;
  bottom: 34px;
  transform: translate3d(-50%, 0, 2PX); }

.bar {
  height: 110px;
  background: #FFFFFF;
  border-radius: 55px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  transition: all 0.3s ease-in-out; }
  .bar.fixed {
    opacity: 1; }
  .bar.fixedHidden {
    bottom: -160px;
    opacity: 0;
    pointer-events: none; }

.navItem {
  flex: 1;
  display: flex;
  font-size: 24px;
  color: #767676;
  position: relative; }
  .navItem .link {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1; }
    .navItem .link span {
      transform: scale(0.83);
      transform-origin: center top;
      margin-top: 12px; }
    .navItem .link svg {
      width: 40px;
      height: 40px;
      margin-top: 12px;
      fill: #767676;
      flex-grow: 0;
      flex-shrink: 0; }
    .navItem .link .icon {
      width: 40px;
      height: 40px;
      margin-top: 12px;
      flex-grow: 0;
      flex-shrink: 0; }
      .navItem .link .icon:nth-child(2) {
        display: none; }
  .navItem .linkActive {
    color: #FF4D4D; }
    .navItem .linkActive svg {
      fill: #FF4D4D; }
    .navItem .linkActive .png-icon:nth-child(1) {
      display: none; }
    .navItem .linkActive .png-icon:nth-child(2) {
      display: block; }
    .navItem .linkActive .avatar {
      box-shadow: inset 0 0 0px 2px #FF4D4D; }
      .navItem .linkActive .avatar:after {
        display: none; }
  .navItem .menu {
    position: absolute;
    top: -96px;
    background: #F8F8F8;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    width: 164px;
    left: 50%;
    margin-left: -82px; }
    .navItem .menu .list .li {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 92px;
      font-size: 24px;
      color: #767676;
      font-weight: bold;
      position: relative; }
      .navItem .menu .list .li.active {
        color: #FF4D4D;
        background: #fff; }
      .navItem .menu .list .li:after {
        content: " ";
        display: block;
        left: 16px;
        right: 16px;
        height: 1px;
        bottom: 0;
        background: #f0f0f0; }
      .navItem .menu .list .li:last-child:after {
        display: none; }

.avatar {
  width: 42px;
  height: 42px;
  margin-top: 10px;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: inset 0 0 0px 2px #767676;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .avatar img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: block;
    object-fit: cover;
    overflow: hidden; }

.fade:global(-enter-active), .fade:global(-leave-active) {
  transition: all .2s; }

.fade:global(-leave-active), .fade:global(-leave-to), .fade:global(-enter) {
  transform: translate(0, 10px);
  opacity: 0; }
